import {
  Autocomplete,
  Box,
  Divider,
  Group,
  Loader,
  Overlay,
  Stack,
  Text
} from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import { IconSearch } from '@tabler/icons'
import { useTranslations } from 'next-intl'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import React, { forwardRef, useRef, useState } from 'react'
import useSWR from 'swr'
import { searchAPI_v2 } from '../../utils/axios'
import capitalize from '../../utils/capitalize'

export const trimText = (text, length) => {
  if (!text) return ''
  if (text.length > length) {
    return text.substring(0, length) + '...'
  }
  return text
}

export const SelectItem = forwardRef(
  ({ thumbnail, name, year, type, uid, inputRef, ...others }, ref) => {
    const handleClick = () => {
      inputRef.current.blur()
    }
    const t = useTranslations('Index')

    return (
      <Link href={`/${type.toLowerCase()}/${uid}`} onClick={handleClick} passHref>
        <Box
          ref={ref}
          {...others}
          mx={8}
          sx={(theme) => ({
            transition:
              'transform 0.2s ease, background-color 0.2s ease, box-shadow 0.2s ease',
            '&:hover': {
              backgroundColor:
                theme.colorScheme === 'dark'
                  ? theme.colors.dark[5]
                  : theme.colors.gray[2],
              boxShadow: theme.shadows.sm,
              transform: 'scale(1.03)'
            },
            borderRadius: theme.radius.sm,
            padding: 6
          })}
        >
          <Group noWrap>
            <Image
              src={`https://wsrv.nl/?url=${thumbnail}&w=100`}
              width={40}
              height={60}
              alt="poster"
              style={{ borderRadius: '4px' }}
            />
            <Stack spacing={0}>
              <Text size="sm">{trimText(name, 40)}</Text>
              <Text size="xs" c="dimmed">
                {t(capitalize(type))}
                {year && !isNaN(year) ? `, ${year}` : ''}
              </Text>
            </Stack>
          </Group>
        </Box>
      </Link>
    )
  }
)

SelectItem.displayName = 'SelectItem'
SelectItem.propTypes = {
  thumbnail: PropTypes.string,
  name: PropTypes.string,
  year: PropTypes.string,
  type: PropTypes.string,
  uid: PropTypes.string,
  inputRef: PropTypes.object
}

const DropdownComponent = ({ movies, inputRef }) => {
  const router = useRouter()
  const language = router.locale.split('-')[0]
  return (
    <Stack p={6} sx={{ width: '100%' }}>
      <Box>
        {movies.map((item, index) => {
          const key = `title_${language}`
          const title = item[key] || item.title
          console.log({ item })
          return (
            <React.Fragment key={item.slug}>
              <SelectItem
                thumbnail={item.poster}
                name={title}
                year={new Date(item.release_date).getFullYear().toString()}
                type={item.type}
                uid={item.slug}
                inputRef={inputRef}
              />
              {index !== movies.length - 1 && <Divider my={4} />}
            </React.Fragment>
          )
        })}
      </Box>
    </Stack>
  )
}

DropdownComponent.propTypes = {
  children: PropTypes.node,
  searchTerm: PropTypes.string,
  movies: PropTypes.array,
  inputRef: PropTypes.object
}

DropdownComponent.defaultProps = {
  movies: []
}

const SearchInput = ({ label, width = '100%' }) => {
  const [value, setValue] = useState('')
  const [debounced] = useDebouncedValue(value, 200)
  const router = useRouter()
  const t = useTranslations('Navbar')

  const language = router.locale.split('-')[0]

  const languageQuery = ['es', 'pt'].includes(language)
    ? `&language=${language}`
    : ''

  const inputRef = useRef(null)
  const [isFocused, setIsFocused] = useState(false)

  const url = `/api/api_ext/content/search?&type=all&sort=popularity&sort_order=desc${languageQuery}&q=`
  const fetcher = async (url) => {
    const res = await fetch(url)
    return res.data
  }

  const { data: response, isLoading } = useSWR(url + debounced, fetcher)

  const handleSubmit = (e) => {
    e.preventDefault()
    router.push(`/search?q=${value}`)
    inputRef.current.blur()
  }

  return (
    <Box component="form" sx={{ width }} onSubmit={handleSubmit} position="relative">
      {isFocused && (
        <Overlay
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            zIndex: 1
          }}
          onClick={() => setIsFocused(false)}
        />
      )}

      <Autocomplete
        placeholder={t('searchPlaceholder')}
        sx={{
          width: '95%',
          borderRadius: '10px',
          boxShadow: '0px -3px 0px 0px rgba(123,57,182,1) inset;',
          position: 'relative',
          zIndex: 2
        }}
        itemComponent={SelectItem}
        label={label}
        name="search"
        data={response || []}
        value={value}
        limit={10}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={(v) => setValue(v)}
        dropdownComponent={({ children }) => (
          <DropdownComponent
            searchTerm={value}
            movies={response || []}
            inputRef={inputRef}
          >
            {children}
          </DropdownComponent>
        )}
        ref={inputRef}
        filter={() => true}
        rightSection={isLoading ? <Loader size={14} /> : null}
        transition="fade"
        transitionProps={{
          duration: 300
        }}
        icon={<IconSearch size={14} stroke={1.5} />}
      />
    </Box>
  )
}

SearchInput.propTypes = {
  label: PropTypes.string,
  width: PropTypes.string
}

export default SearchInput
