import countries from '../utils/countries.json'

export const initials = (str) => {
  if (!str || typeof str !== 'string') return ''
  return str
    .split(' ')
    .map((s) => s.trim()[0])
    .join()
}

export const getCountryNameFromISO = (iso) => {
  if (!iso) return ''
  const country = countries.find((c) => c.code.toLowerCase() === iso.toLowerCase())
  return country ? country.name : ''
}
