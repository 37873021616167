import axios from 'axios'
import { getSession } from 'next-auth/react'

const searchAPI = axios.create({
  baseURL: process.env.NEXT_PUBLIC_EXTERNAL_API_URL
})

const searchAPI_v2 = axios.create({
  baseURL: process.env.EXTERNAL_API_URL_V2
})

searchAPI_v2.interceptors.request.use(
  async (config) => {
    config.headers['x-api-key'] = process.env.API_KEY
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

searchAPI.interceptors.request.use(
  async (config) => {
    const session = await getSession()
    if (session && session.accessToken) {
      config.headers['Authorization'] = `Bearer ${session.accessToken}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

const nextAPI = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL
})

const metarankAPI = axios.create({
  baseURL: process.env.NEXT_PUBLIC_EXTERNAL_API_URL
})

metarankAPI.interceptors.request.use(
  async (config) => {
    if (config.url.includes('/metarank')) {
      config.headers['x-api-key'] = process.env.METARANK_API_KEY
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export { metarankAPI, nextAPI, searchAPI, searchAPI_v2 }
