import { Button, Divider, Flex, Modal, Stack, Text, TextInput } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { useTranslations } from 'next-intl'
import Image from 'next/image'
import Link from 'next/link'
import { useState } from 'react'

export default function Footer() {
  const isMobile = useMediaQuery('(max-width: 768px)')
  const [modalState, setModalState] = useState({ newsletter: false, contact: false })
  const t = useTranslations('Footer')
  const openModal = (type) => {
    setModalState({ ...modalState, [type]: true })
  }

  const closeModal = (type) => {
    setModalState({ ...modalState, [type]: false })
  }

  const buttonStyles = {
    backgroundColor: '#4A1BD0',
    fontSize: '10px',
    width: 'auto',
    alignSelf: 'center'
  }

  const stackTextStyle = {
    fontWeight: '100',
    color: '#FFFFFF',
    textDecoration: 'none'
  }

  return (
    <footer style={{ backgroundColor: '#121E27', marginTop: '100px' }}>
      <div
        style={{
          maxWidth: '1366px',
          margin: '0 auto',
          width: '100%'
        }}
      >
        <Stack align="center">
          {isMobile ? (
            <div style={{ marginBottom: '10px' }}>
              <Image src="/sd_logo_footer.png" width={174} height={52} alt="logo" />
            </div>
          ) : (
            <>
              <Flex
                justify="center"
                align="center"
                sx={{
                  width: isMobile ? '90vw' : '80vw',
                  maxWidth: '1366px',
                  marginBottom: '5px'
                }}
              >
                <div style={{ marginTop: '40px' }}>
                  <Image
                    src="/sd_logo_footer.png"
                    width={174}
                    height={52}
                    alt="logo"
                  />
                </div>
                <Stack
                  spacing={20}
                  sx={{ flexGrow: 1, marginLeft: '20px', marginTop: '20px' }}
                >
                  <Flex gap={12} sx={{ justifyContent: 'flex-end' }}>
                    <Button
                      sx={buttonStyles}
                      onClick={() => openModal('newsletter')}
                    >
                      {t('joinNewsletter')}
                    </Button>
                    <Button
                      sx={{ ...buttonStyles, backgroundColor: '#7B39B6' }}
                      component="a"
                      href="https://t.me/StreamDiscoverBot"
                      target="_blank"
                    >
                      {t('joinTelegram')}
                    </Button>
                  </Flex>
                  <Flex
                    justify="flex-end"
                    align="flex-start"
                    mt={10}
                    sx={{
                      fontSize: '11px',
                      textTransform: 'uppercase',
                      marginLeft: 'auto',
                      textAlign: 'right'
                    }}
                    gap={150}
                  >
                    <Stack spacing={10} sx={{ textAlign: 'right' }}>
                      <Text
                        size={12}
                        style={{ fontWeight: '700', color: '#FFFFFF4D' }}
                      >
                        {t('whatToWatch')}
                      </Text>
                      <Text style={stackTextStyle}>{t('somethingToWatch')}</Text>
                      <Text style={stackTextStyle}>{t('swipeContent')}</Text>
                      <Text style={stackTextStyle}>{t('epg')}</Text>
                    </Stack>
                    <Stack spacing={10} sx={{ textAlign: 'right' }}>
                      <Text
                        size={12}
                        style={{ fontWeight: '700', color: '#FFFFFF4D' }}
                      >
                        {t('whatToDiscover')}
                      </Text>
                      <Link href="/hits" passHref>
                        <Text as="a" style={stackTextStyle}>
                          {t('hits')}
                        </Text>
                      </Link>
                      <Link href="/services" passHref>
                        <Text as="a" style={stackTextStyle}>
                          {t('streamingServices')}
                        </Text>
                      </Link>
                      <Link href="/contents" passHref>
                        <Text as="a" style={stackTextStyle}>
                          {t('contents')}
                        </Text>
                      </Link>
                    </Stack>
                    <Stack spacing={10} sx={{ textAlign: 'right' }}>
                      <Text
                        size={12}
                        style={{ fontWeight: '700', color: '#FFFFFF4D' }}
                      >
                        {t('company')}
                      </Text>
                      <Text style={stackTextStyle}>{t('forBusiness')}</Text>
                      <Text
                        style={{ ...stackTextStyle, cursor: 'pointer' }}
                        onClick={() => openModal('contact')}
                      >
                        {t('contactUs')}
                      </Text>
                      <Link href="/terms-privacy" passHref>
                        <Text as="a" style={stackTextStyle}>
                          {t('terms')}
                        </Text>
                      </Link>
                    </Stack>
                  </Flex>
                </Stack>
              </Flex>
              <Divider sx={{ width: '100%' }} />
            </>
          )}
        </Stack>
      </div>

      <Modal
        opened={modalState.newsletter}
        onClose={() => closeModal('newsletter')}
        title={t('joinNewsletterLower')}
      >
        <TextInput placeholder={t('enterEmail')} label="Email" required />
        <Button
          fullWidth
          mt="md"
          onClick={() => closeModal('newsletter')}
          sx={{ backgroundColor: '#7B39B6' }}
        >
          {t('subscribe')}
        </Button>
      </Modal>

      <Modal
        opened={modalState.contact}
        onClose={() => closeModal('contact')}
        title={t('contactUsLower')}
      >
        <TextInput placeholder={t('enterEmail')} label="Email" required />
        <TextInput
          placeholder={t('enterMessage')}
          label={t('message')}
          required
          multiline
          minRows={3}
        />
        <Button fullWidth mt="md" onClick={() => closeModal('contact')}>
          {t('send')}
        </Button>
      </Modal>
    </footer>
  )
}
