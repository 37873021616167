import { Select } from '@mantine/core'
import { IconLanguage } from '@tabler/icons'
import React from 'react'

const LanguageInput = ({ language, setLanguage, options, ...other }) => {
  return (
    <Select
      icon={<IconLanguage size={20} />}
      placeholder="Pick one"
      data={options}
      value={language}
      onChange={setLanguage}
      styles={(theme) => ({
        input: {
          fontSize: theme.fontSizes.sm,
          backgroundColor: '#121E274D',
          padding: '4px 8px',
          borderRadius: '10px',
          border: 'none',
          borderBottom: '2px solid #7B39B6',
          opacity: 1,
          position: 'relative'
        },
        dropdown: {
          zIndex: 1000
        },
        item: {
          fontSize: theme.fontSizes.xs
        }
      })}
      {...other}
    />
  )
}

export default LanguageInput
