import { MultiSelect } from '@mantine/core'
import { useLocaleContext } from '../../context/locale'

const PlatformInput = (props) => {
  const { platforms, setPlatforms } = useLocaleContext()

  return (
    <MultiSelect
      label={props.label}
      data={props.platforms}
      placeholder="Select your preferred platforms"
      value={platforms || ''}
      onChange={setPlatforms}
      dropdownPosition="bottom"
      withinPortal
      sx={{ width: '80%' }}
      styles={(theme) => ({
        input: {
          fontSize: theme.fontSizes.sm,
          backgroundColor: '#121E274D',
          padding: '4px 8px',
          borderRadius: '10px',
          border: 'none',
          borderBottom: '2px solid #7B39B6',
          opacity: 1,
          position: 'relative'
        },
        dropdown: {
          zIndex: 1000
        },
        item: {
          fontSize: theme.fontSizes.xs
        }
      })}
    />
  )
}

export default PlatformInput
